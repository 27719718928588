import React, { ReactNode, useContext, useEffect } from 'react';
import { StudentContext } from '../../pages/StudentDetail/StudentDetail';
import InstituteService from '../../services/InstituteService';
import JobTrackerService from '../../services/JobTrackerServices';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
interface Props {
  children: ReactNode;
}

export const StudentProvider: React.FC<Props> = ({ children }) => {
  const [studentData, setStudentData] = React.useState<any>(null);
  const [studentDataLoading, setStudentDataLoading] =
    React.useState<any>(false);

  const appName = document.location.pathname.split('/')[1];
  const { studentId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!studentId) {
      navigate(-1);
      return;
    }

    (async () => {
      try {
        setStudentDataLoading(true);
        const { data } = await InstituteService.getStudentDetails(
          appName,
          studentId
        );
        let studentUserInfo = {};
        const userDetailGetResponse = await JobTrackerService.getUserDetailById(
          data?.userId
        );
        studentUserInfo = {
          phoneNo: userDetailGetResponse?.data[0]?.phoneNo,
          gender: userDetailGetResponse?.data[0]?.gender,
          dob: userDetailGetResponse?.data[0]?.dob,
          country: userDetailGetResponse?.data[0]?.country,
        };
        if (!data.userId) {
          data.userId = data?.email;
        }
        setStudentData({ ...data, userInfo: studentUserInfo });
      } catch (error) {
        console.log(error);
        navigate(-1);
      } finally {
        setStudentDataLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId, appName]);

  const studentValue = React.useMemo(
    () => ({
      studentData,
      setStudentData,
      studentDataLoading,
    }),
    [studentData, setStudentData, studentDataLoading]
  );

  return (
    <StudentContext.Provider value={studentValue}>
      {children}
    </StudentContext.Provider>
  );
};

export const useStudent = () => useContext(StudentContext);
