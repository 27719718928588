import axios, { InternalAxiosRequestConfig } from 'axios';
import { auth } from '../utils/firebase/firebaseIndex';

export const instituteClient = axios.create({
  baseURL: process.env.VITE_APP_BACKEND_BASE_URL + '/instituteApp',
});

// let previousRequestCancelToken: any = null;

instituteClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    if (config && config.headers && auth.currentUser) {
      const accessToken = await auth.currentUser.getIdToken();
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },

  (error: any) => Promise.reject(error)
);
const getStudents = (
  instituteId: string,
  studentId?: string,
  payload?: any,
  queryParams?: Record<string, string>[]
) => {
  // if (previousRequestCancelToken) {
  //   previousRequestCancelToken.cancel('Request canceled due to new request');
  // }
  // Cancel the previous request if there is one
  // Create a new cancel token source for the current request
  // const cancelTokenSource = axios.CancelToken.source();
  // previousRequestCancelToken = cancelTokenSource;

  let baseURL =
    `institute/${instituteId}/students` + (studentId ? `/${studentId}` : '');

  if (queryParams?.length) {
    queryParams.forEach((param, index) => {
      baseURL +=
        (index === 0 ? '?' : '&') +
        `${Object.keys(param)[0]}=${Object.values(param)[0]}`;
    });
  }
  return instituteClient.post(baseURL, payload);
};

const getStudentDetails = (instituteId: string, studentId?: string) => {
  return instituteClient.get(
    `institute/${instituteId}/studentsDetails/${studentId}`
  );
};
const getCoachDetails = (instituteId: string, coachId?: string) => {
  return instituteClient.get(
    `institute/${instituteId}/coachDetails/${coachId}`
  );
};

const getCoaches = (
  instituteId: string,
  coachId?: string,
  payload?: any,
  queryParams?: Record<string, string>[]
) => {
  let baseURL =
    `institute/${instituteId}/coaches` + (coachId ? `/${coachId}` : '');

  if (queryParams?.length) {
    queryParams.forEach((param, index) => {
      baseURL +=
        (index === 0 ? '?' : '&') +
        `${Object.keys(param)[0]}=${Object.values(param)[0]}`;
    });
  }
  return instituteClient.post(baseURL, payload);
};

const getInstitute = (instituteId: string) => {
  return instituteClient.get(`/institute/${instituteId}`);
};

const getJobs = (instituteId: string) => {
  return instituteClient.post(`/institute/${instituteId}/getJobs`);
};

const getGroups = (
  instituteId: string,
  groupId?: string,
  payload?: any,
  queryParams?: Record<string, string>[]
) => {
  let baseURL =
    `/institute/${instituteId}/getGroup` + (groupId ? `/${groupId}` : '');
  if (queryParams?.length) {
    queryParams.forEach((param, index) => {
      baseURL +=
        (index === 0 ? '?' : '&') +
        `${Object.keys(param)[0]}=${Object.values(param)[0]}`;
    });
  }
  return instituteClient.post(baseURL, payload);
};

const assignJob = (instituteId: string, payload: any) => {
  return instituteClient.post(`/institute/${instituteId}/assignJob`, payload);
};

const getResources = (instituteId: string) => {
  return instituteClient.get(`/institute/${instituteId}/resource`);
};

const addResources = (instituteId: string, payload: any) => {
  return instituteClient.post(`/institute/${instituteId}/resource`, payload);
};
const editResources = (instituteId: string, payload: any) => {
  return instituteClient.put(`/institute/${instituteId}/resource`, payload);
};
const deleteInstituteJob = (instituteId: string, jobId: string) => {
  return instituteClient.put(`/institute/${instituteId}/deleteJob/${jobId}`);
};

const getStudentNotes = (instituteId: string, userid: string) => {
  return instituteClient.get(
    `/institute/${instituteId}/notes?studentId=${userid}`
  );
};

const postStudentNotes = (instituteId: string, payload: any) => {
  return instituteClient.post(
    `/institute/${instituteId}/notes?studentId=${payload.userId}`,
    payload
  );
};

const deleteStudentNote = (
  instituteId: string,
  userId: string,
  noteId: string
) => {
  return instituteClient.delete(
    `/institute/${instituteId}/notes/${noteId}?studentId=${userId}`
  );
};

const editStudentNote = (
  instituteId: string,
  userId: string,
  noteId: string,
  payload: any
) => {
  return instituteClient.put(
    `/institute/${instituteId}/notes/${noteId}?studentId=${userId}`,
    payload
  );
};

const deleteResource = (instituteId: string, resourceId: string) => {
  return instituteClient.delete(
    `/institute/${instituteId}/resource/${resourceId}`
  );
};

const getCredits = (instituteId: string) => {
  return instituteClient.get(`/institute/${instituteId}/getCredits`);
};

const createActivity = (
  instituteId: string,
  studentId: string,
  payload: any
) => {
  return instituteClient.post(
    '/institute/' + instituteId + '/activity/' + studentId,
    payload
  );
};

const postAiTools = (
  instituteId: string,
  userData: any,
  studentData: any,
  serviceName: string,
  input: any
) => {
  const payload = {
    usedBy: {
      input: input,
      serviceId: serviceName,
      status: 'INPROGRESS',
      usedFor: { name: studentData?.fullName, email: studentData?.email },
      name: `${userData?.fname} ${userData?.lname}`,
      email: userData?.email,
    },
  };
  return instituteClient.post(`/institute/${instituteId}/aiTools`, payload);
};

const getAiServices = (instituteId: string) => {
  return instituteClient.get(`/institute/${instituteId}/aiTools`);
};

const getAiServicesById = (instituteId: string, serviceId: string) => {
  return instituteClient.get(`/institute/${instituteId}/aiTools/${serviceId}`);
};
const getAllInvites = (instituteId: string) => {
  return instituteClient.get(`/institute/${instituteId}/invite`);
};

const cancelInvite = (instituteId: string, studentId: string) => {
  return instituteClient.post(
    `/institute/${instituteId}/cancelInvite/${studentId}`,
    {}
  );
};
const reInvite = (
  instituteId: string,
  studentId: string,
  fname: string,
  email: string
) => {
  return instituteClient.post(
    `/institute/${instituteId}/reInvite/${studentId}`,
    {
      firstName: fname,
      email,
    }
  );
};
const resendInvite = (
  instituteId: string,
  studentId: string,
  fname: string,
  email: string
) => {
  return instituteClient.post(
    `/institute/${instituteId}/resendInvite/${studentId}`,
    {
      firstName: fname,
      email,
    }
  );
};

const updateCoach = (coachId: string, instituteId: string, payload: any) => {
  return instituteClient.put(
    `/institute/${instituteId}/updateCoach/${coachId}`,
    payload
  );
};

const updateStudent = (coachId: string, instituteId: string, payload: any) => {
  return instituteClient.put(
    `/institute/${instituteId}/updateStudent/${coachId}`,
    payload
  );
};

const deleteStudent = (studentId: string, instituteId: string) => {
  return instituteClient.put(
    `/institute/${instituteId}/deleteStudent/${studentId}`
  );
};

const deleteCoach = (coachId: string, instituteId: string) => {
  return instituteClient.put(
    `/institute/${instituteId}/deleteCoach/${coachId}`
  );
};

const deactivateStudent = (instituteId: string, studentId: string) => {
  return instituteClient.post(
    `/institute/${instituteId}/deactivateStudent/${studentId}`
  );
};

const activateStudent = (instituteId: string, studentId: string) => {
  return instituteClient.post(
    `/institute/${instituteId}/activateStudent/${studentId}`
  );
};

const deactivateCoach = (instituteId: string, coachId: string) => {
  return instituteClient.post(
    `/institute/${instituteId}/deactivateCoach/${coachId}`
  );
};

const activateCoach = (instituteId: string, coachId: string) => {
  return instituteClient.post(
    `/institute/${instituteId}/activateCoach/${coachId}`
  );
};

const getInstituteContacts = (instituteId: string) => {
  return instituteClient.get(`/institute/${instituteId}/contact`);
};

const assignContactToStudents = (instituteId: string, payload: any) => {
  return instituteClient.post(
    `/institute/${instituteId}/contact/assign-to-students`,
    payload
  );
};

const updateInstituteJob = (instituteId: string, payload: any) => {
  return instituteClient.put(`/institute/${instituteId}/updateJob`, payload);
};

const InstituteService = {
  getInstituteContacts,
  getStudents,
  getStudentDetails,
  getCoaches,
  getInstitute,
  getCoachDetails,
  getJobs,
  updateInstituteJob,
  assignJob,
  getGroups,
  deleteInstituteJob,
  getStudentNotes,
  postStudentNotes,
  deleteStudentNote,
  editStudentNote,
  getResources,
  addResources,
  deleteResource,
  getCredits,
  postAiTools,
  editResources,
  getAiServices,
  getAiServicesById,
  createActivity,
  getAllInvites,
  cancelInvite,
  reInvite,
  resendInvite,
  updateCoach,
  deleteStudent,
  updateStudent,
  deleteCoach,
  deactivateStudent,
  activateStudent,
  deactivateCoach,
  activateCoach,
  assignContactToStudents,
};

export default InstituteService;
