import {
  DownOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Row, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { handleOnKeyDown } from '../../utils/eventListeners';
import './SideNav.less';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Paragraph, { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { EllipsisConfig } from 'antd/lib/typography/Base';
import { AppContext } from '../../auth';
import { ReactComponent as Vectorstudent } from '../../images/Vectorstudent.svg';
import { ReactComponent as Coach } from '../../images/Vectorcoach.svg';


const TooltipParagraph: React.FC<ParagraphProps> = ({
  children,
  ellipsis,
  ...props
}) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip title={truncated ? children : undefined}>
      <Paragraph
        {...props}
        ellipsis={{ ...(ellipsis as EllipsisConfig), onEllipsis: setTruncated }}
      >
        {/* NOTE: Fragment is necessary to avoid showing the title */}
        <>{children}</>
      </Paragraph>
    </Tooltip>
  );
};

const SideNav = ({ shrink, setShrink, institute }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const appName = window.location.pathname.split('/')[1];
  const { t } = useTranslation();

  const { studentName, userInfo } = useContext(AppContext);

  const [selectedItem, setSelectedItem] = React.useState<string>('/');

  useEffect(() => {
    setSelectedItem(decodeURIComponent(location.pathname));
  }, [appName, location]);

  const onClickStudent = () => {
    navigate(`/${studentName.toLowerCase()}`);
  };

  const menuItems: ItemType[] = [
    {
      key: `/${studentName.toLowerCase()}`,
      label: `${studentName}s`,
      icon: <Vectorstudent />,
      style: {
        color:
          selectedItem === `/${studentName.toLowerCase()}`
            ? '#0062eb'
            : undefined,
      },
      title: '',
    },
    {
      key: '/my-teams',
      label: `My Teams`,
      icon: <TeamOutlined style={{ height: '17.79px' }} />,
      style: { color: selectedItem === '/my-teams' ? '#0062eb' : undefined },
      title: 'Coaches',
    },
  ];

  const handleInstituteChange = (value: string) => {
    localStorage.setItem('loggedInApp', value);
    window.location.replace(window.location.origin + '/' + value);
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #CFD2D7',
        fontSize: '15px',
        color: '#96A0B5',
      }}
    >
      <div>
        <Row
          style={{
            height: '72px',
            borderBottom: '1px solid #F2F4F7',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'grid',
              alignItems: 'center',
              justifyContent: 'flex-start',
              cursor: 'pointer',
              gridTemplateColumns: shrink ? '100%' : '25% 75%',
              width: '100%',
            }}
            // onClick={() => navigate(`/${studentName.toLowerCase()}`)}
            // onKeyDown={(e) =>
            //   handleOnKeyDown(e, () =>
            //     navigate(`/${studentName.toLowerCase()}`)
            //   )
            // }
            role="button"
            tabIndex={0}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 8px 0 8px',
              }}
            >
              <img
                src={institute?.logoUrl}
                alt="logo"
                width={45}
                height={45}
                style={{ objectFit: 'contain' }}
              />
            </div>
            {shrink ? null : (
              <div className="institute-selector">
                {Object.keys(
                  (userInfo?.instituteRoles as Record<any, any>) || {}
                ).length > 1 ? (
                  <Dropdown
                    menu={{
                      items: [
                        ...Object.keys(
                          (userInfo?.instituteRoles as Record<any, any>) || {}
                        )
                          .filter((item) => item !== appName)
                          .map((institute) => ({
                            key: institute,
                            label: (
                              <div
                                onClick={() => handleInstituteChange(institute)}
                                onKeyDown={(e) =>
                                  handleOnKeyDown(e, () =>
                                    handleInstituteChange(institute)
                                  )
                                }
                                role="button"
                                tabIndex={0}
                                style={{ width: '100%' }}
                              >
                                {institute}
                              </div>
                            ),
                          })),
                      ],
                    }}
                    trigger={['click']}
                  >
                    <span
                      style={{
                        display: 'inline-flex',
                        gap: '1rem',
                        alignItems: 'center',
                      }}
                    >
                      <TooltipParagraph style={{ width: '78%' }}>
                        {institute?.name}
                      </TooltipParagraph>
                      <DownOutlined height={12} />
                    </span>
                  </Dropdown>
                ) : (
                  <TooltipParagraph>{institute?.name}</TooltipParagraph>
                )}
              </div>
            )}
          </div>
        </Row>
        <Menu
          mode="inline"
          onClick={(e) => {
            navigate(`${e.key}`);
            ReactGA.event({
              category: 'Side Nav',
              action: 'Navigate ' + e.key,
            });
          }}
          onOpenChange={() => (shrink ? null : onClickStudent())}
          style={{
            borderRight: 0,
            fontSize: '14px',
            color: '#152C5B',
            width: '92%',
            margin: 'auto',
            paddingTop: '1rem',
            fontWeight: '500',
          }}
          defaultSelectedKeys={['/']}
          selectedKeys={[selectedItem]}
          items={
            menuItems?.map((item: any) => ({
              ...item,
              style: {
                ...item?.style,
                color: item?.key === selectedItem ? '#0062eb' : '#152C5B',
              },
            })) as any
          }
        />
      </div>
      <div
        style={{
          marginTop: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          gap: '1rem',
          paddingBottom: '1rem',
        }}
      >
        <Button
          size="small"
          onClick={() => setShrink(!shrink)}
          style={{ width: '100%' }}
          type="default"
        >
          {shrink ? <RightCircleOutlined /> : <LeftCircleOutlined />}
        </Button>
      </div>
    </div>
  );
};

export default SideNav;
