import { lazy, Suspense, useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
('./App.less');
import {
  RESET_RESUME_SETTING,
  useResumeSettingsStore,
} from '@careerflow/common-components/dist/components/ResumeBuilder/store/resumeSettings';
import InitService, {
  APP,
} from '@careerflow/common-components/dist/services/InitService';
import './auth/axios';
import {
  analytics,
  appCheck,
  auth,
  functions,
  logInWithEmailAndPassword,
  logout,
  perf,
  registerWithEmailAndPassword,
  signInWithGoogle,
  storage,
  storageRef,
} from './utils/firebase/firebaseIndex';

import bg from './locales/bg/translation.json';
import cs from './locales/cs/translation.json';
import da from './locales/da/translation.json';
import de from './locales/de/translation.json';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import et from './locales/et/translation.json';
import fi from './locales/fi/translation.json';
import fr from './locales/fr/translation.json';
import hi from './locales/hi/translation.json';
import hr from './locales/hr/translation.json';
import hu from './locales/hu/translation.json';
import it from './locales/it/translation.json';
import lt from './locales/lt/translation.json';
import lv from './locales/lv/translation.json';
import nl from './locales/nl/translation.json';
import no from './locales/no/translation.json';
import pl from './locales/pl/translation.json';
import pt from './locales/pt/translation.json';
import ro from './locales/ro/translation.json';
import sk from './locales/sk/translation.json';
import sl from './locales/sl/translation.json';
import sr from './locales/sr/translation.json';
import sv from './locales/sv/translation.json';
import zh from './locales/zh/translation.json';
import { config } from './utils/apiPaths';
import { AppContext } from './auth';
import { ROUTES } from './types/Routes';
import { CommonLayout, LayoutOnboarding, ProtectedRoute } from './layout';
import './App.less';
import { initDB } from 'react-indexed-db-hook';
import StudentDetail, { StudentContext } from './pages/StudentDetail/StudentDetail';
import { StudentLayout, StudentProviderLayout } from './layout/StudentLayout';

const DBConfig = {
  name: 'CareerflowDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'resume',
      storeConfig: { keyPath: 'name', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: true } },
      ],
    },
    {
      store: 'resumeBuilder',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
    },
  ],
};

const ResumeBuilderList = lazy(() => import('./pages/ResumeBuilderList'));

const ResumeBuilder = lazy(() => import('./pages/ResumeBuilder'));

const AuthScreen = lazy(() => import('./pages/Signup/AuthScreen'));

const SelectInstitute = lazy(() => import('./pages/SelectInstitute'));

const Students = lazy(() => import('./pages/Students'));

const Coaches = lazy(() => import('./pages/Coaches'));

const AddStudent = lazy(() =>
  import('./pages/AddStudent')
);

const AddCoach = lazy(() => import('./pages/AddCoach'));

const CoachDetail = lazy(() =>
  import('./pages/CoachDetail')
);

const CoachOverview = lazy(() =>
  import('./pages/CoachDetail/Overview')
);

const EditStudent = lazy(() =>
  import('./pages/EditStudent')
);

const EditCoach = lazy(() =>
  import('./pages/EditCoach')
);


try {
  initDB(DBConfig);
} catch (err) {
  console.log('Error while initializing indexed DB');
}

function App() {
  const { institute, studentName } = useContext(AppContext);

  const studentNameLowerCase = studentName.toLowerCase();

  const setResumeSettings = useResumeSettingsStore(
    (state: any) => state.setResumeSettings
  );

  const appName = window.location.pathname.split('/')[1];

  const handleClearResumeSettingsStore = () => {
    setResumeSettings(RESET_RESUME_SETTING);
  };

  useEffect(() => {
    InitService.init(
      config.baseUrl ?? '',
      AppContext,
      {
        analytics,
        appCheck,
        auth,
        functions,
        logInWithEmailAndPassword,
        logout,
        perf,
        registerWithEmailAndPassword,
        signInWithGoogle,
        storage,
        triggerResetEmail: () => null,
        storageRef,
      },
      APP.CF_RESUME_BUILDER,
      StudentContext,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      {
        en,
        bg,
        cs,
        da,
        de,
        es,
        et,
        fi,
        fr,
        hi,
        hr,
        hu,
        it,
        lt,
        lv,
        nl,
        no,
        pl,
        pt,
        ro,
        sk,
        sl,
        sr,
        sv,
        zh,
      } as any
    );
  }, []);
  return (
    <Suspense>
      <Routes>
        <Route element={<LayoutOnboarding />}>
          {!appName && (
            <Route path={ROUTES.HOME} element={<SelectInstitute />} />
          )}
          <Route
            path={ROUTES.LOGIN}
            element={
              <AuthScreen
                instituteLogo={institute?.logoUrl}
                instituteName={institute?.name}
              />
            }
          />
          <Route path="*" element={<Navigate to={ROUTES.LOGIN} replace />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route
            path={ROUTES.HOME}
            element={<Navigate to={`/${studentNameLowerCase}`} replace />}
          />
          <Route element={<CommonLayout institute={institute} />}>
            <Route path={`/${studentNameLowerCase}`} element={<Students />} />
            <Route
              path={`/${studentNameLowerCase}/add`}
              element={<AddStudent />}
            />
            <Route path={ROUTES.MY_TEAMS} element={<Coaches />} />
            <Route path={`${ROUTES.MY_TEAMS}/add`} element={<AddCoach />} />
            <Route path={`${ROUTES.MY_TEAMS}/:coachId`} element={<CoachDetail />}>
              <Route path="" element={<CoachOverview />} />
              <Route path="edit" element={<EditCoach />} />
            </Route>
            <Route
              path={`/${studentNameLowerCase}/:studentId`}
              element={<StudentProviderLayout />}
            >
              <Route
                path={""}
                element={<Navigate to={"resume-builder"} replace />}
              />
              <Route

                element={
                  <StudentLayout>
                    <StudentDetail />
                  </StudentLayout>
                }
              >
                <Route
                  path="resume-builder"
                  element={
                    <ResumeBuilderList
                      handleClearResumeSettingsStore={
                        handleClearResumeSettingsStore
                      }
                    />
                  }
                />
                <Route path="edit" element={<EditStudent />} />
              </Route>

            </Route>
          </Route>

          <Route element={<StudentProviderLayout />}>
            <Route
              path={`/${studentNameLowerCase}/:studentId/resume-builder/editor`}
              element={
                <StudentLayout>
                  <ResumeBuilder
                    handleClearResumeSettingsStore={
                      handleClearResumeSettingsStore
                    }
                  />
                </StudentLayout>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
