import {

  LeftOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Button, Menu } from 'antd';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileInfo from '../profileInfo/ProfileInfo';
import style from './Navbar.module.css';
import { useTranslation } from 'react-i18next';
import { handleOnKeyDown } from '../../utils/eventListeners';
import { useMediaQuery } from 'react-responsive';

const Navbar = ({
  isMenuUnfolded,
  setIsMenuUnfolded,
  shrink,
  back,
}: {
  isMenuUnfolded: boolean;
  setIsMenuUnfolded: (callback: (state: boolean) => boolean) => void;
  shrink: boolean;
  back: boolean;
}) => {
  const menuMarginLeft = shrink
    ? isMenuUnfolded
      ? '0'
      : '4.4rem'
    : isMenuUnfolded
      ? '0'
      : '231px';
  const navigate = useNavigate();
  const globalSearchRef = useRef<HTMLDivElement>(null);
  const backPage = window.location.pathname.split('/')[2];

  const { t } = useTranslation();

  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <Menu
        mode="horizontal"
        defaultSelectedKeys={['mail']}
        style={{
          paddingLeft: '1.5rem',
          lineHeight: '3.25px',
          minHeight: '4.5rem',
          backgroundColor: '#fff',
          color: '#FFFFFF',
          position: 'fixed',
          width: '100%',
          zIndex: '2',
          marginLeft: menuMarginLeft,
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        {back ? (
          <div
            className={style.backButtonClass}
            onClick={() => navigate(`/${backPage}`)}
            onKeyDown={(e) =>
              handleOnKeyDown(e, () => navigate(`/${backPage}`))
            }
            role="button"
            tabIndex={0}
          >
            <LeftOutlined />
            {t('Backtoall')}
          </div>
        ) : (
          <></>
        )}
        {(isMobile || isMenuUnfolded) && (
          <Button
            className={style.menuIcon}
            onClick={() =>
              setIsMenuUnfolded((menuState: boolean) => !menuState)
            }
          >
            {isMenuUnfolded ? <MenuOutlined /> : <MenuUnfoldOutlined />}
          </Button>
        )}
        {!isMobile && (
          <div className={style.title}>
            <div
              id={'globalSearch'}
              className={style.globalSearch}
              style={{
                position: 'relative',
                width: isDesktop ? '620px' : '400px',
              }}
            >
              <div className="search-wrapper" ref={globalSearchRef}></div>
            </div>

            <div className={style.profile_div}>
              <ProfileInfo />
            </div>
          </div>
        )}
      </Menu>
    </>
  );
};

export default Navbar;
