import { Layout } from 'antd';
import React, {
  Fragment,
  ReactNode,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';

import Navbar from '../components/Navbar/Navbar';
import SideNav from '../components/SideNav/SideNav';
import { AppContext } from '../auth';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import { StudentProvider, useStudent } from '../providers/StudentProvider';
import { useMediaQuery } from 'react-responsive';
import { ROUTES } from '../types/Routes';

export const CommonLayout: React.FC<{ institute: any }> = ({ institute }) => {
  const { Header, Content, Sider } = Layout;

  const { studentName } = useContext(AppContext);
  const studentNameLowerCase = studentName.toLowerCase();

  const [isMenuUnfolded, setIsMenuUnfolded] = useState(false);
  const [collapsed, setCollapsed] = useState(!true);
  const [back, setBack] = useState(false);
  const [sideMenu, setSideMenu] = useState('1');

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.startsWith(
        `/${encodeURIComponent(studentNameLowerCase)}/`
      ) ||
      location.pathname.startsWith(`${ROUTES.MY_TEAMS}/`)
    ) {
      setCollapsed(true);
      setBack(true);
    } else {
      if (!isDesktop) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
      setBack(false);
    }
  }, [location, setCollapsed, setBack, studentNameLowerCase, isDesktop]);

  if (!institute) return <Loader />;

  return (
    <Fragment>
      <Header style={{ minHeight: '4.5rem' }}>
        <Navbar
          isMenuUnfolded={isMenuUnfolded}
          setIsMenuUnfolded={setIsMenuUnfolded}
          shrink={collapsed}
          back={back}
        />
      </Header>

      {!isMenuUnfolded && (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            height: '100%',
            position: 'fixed',
            zIndex: 2,
            top: 0,
          }}
          width={231}
          collapsedWidth={70}
          className="site-layout-background"
        >
          <SideNav
            setSideMenu={setSideMenu}
            sideMenu={sideMenu}
            shrink={collapsed}
            setShrink={setCollapsed}
            institute={institute}
          />
        </Sider>
      )}

      <Content
        className="site-layout-background"
        style={{
          minHeight: 'calc(100vh - 5.5rem)',
          backgroundColor: '#FAFAFA',
          position: 'absolute',
          left: collapsed
            ? isMenuUnfolded
              ? 0
              : 70
            : !isMenuUnfolded
              ? '230px'
              : '0',
          width: `calc(100% - ${collapsed ? (isMenuUnfolded ? 0 : 70) : !isMenuUnfolded ? 230 : 0}px)`,
        }}
      >
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Content>
    </Fragment>
  );
};

export const StudentProviderLayout: React.FC = () => {
  return (
    <StudentProvider>
      <Outlet />
    </StudentProvider>
  );
};

interface Props {
  children: ReactNode;
}

export const StudentLayout: React.FC<Props> = ({ children }) => {
  const { studentData } = useStudent();
  if (!studentData) return <Loader />;
  return <Fragment>{children}</Fragment>;
};
