import {
  FileDoneOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import axios from 'axios';
import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../auth';
import LeftSubNav from '../../components/LeftSubNav/LeftSubNav';
import Loader from '../../components/Loader';
import { useStudent } from '../../providers/StudentProvider';
import { StudentContextType } from '../../providers/StudentProvider/context';
import jobTrackerService from '../../services/JobTrackerServices';
import { jobTrackerApiPath } from '../../utils/apiPaths';
import styles from './StudentDetail.module.less';
import { downLoadDisplayPicture } from '../../utils/downloadProfilePicture';

export const StudentContext = React.createContext<StudentContextType>({
  studentData: null,
  setStudentData: () => null,
  studentDataLoading: false,
});

function StudentDetail() {
  const [activeTab, setActiveTab] = useState<string>('/resume-builder');
  const [profilePic, setProfilePic] = React.useState('');
  const [boardLoading, setBoardLoading] = React.useState(false);

  const { studentData, studentDataLoading } = useStudent();

  const appName = document.location.pathname.split('/')[1];
  const [board, setBoard] = React.useState<any>();
  const [sectionList, setSectionList] = React.useState<any[]>([]);
  const [deletedJobList, setDeletedJobList] = React.useState<any[]>([]);
  const [reload, setReload] = useState(false);
  const { studentName } = useContext(AppContext);

  const [studentInvites, setStudentInvites] = useState<any>(null);
  const { t } = useTranslation();



  useEffect(() => {
    if (studentData?.userId) {
      jobTrackerService
        .getUserDetailById(studentData?.userId as string)
        .then((res) => {
          setStudentInvites(res?.data?.[0]?.invites?.[appName]);
        })
        .catch((error) => console.log(error));
    }
  }, [studentData, appName]);

  const menuItems = [
    {
      key: '/resume-builder',
      text: t('resumebuilder'),
      icon: (
        <FileDoneOutlined
          style={{
            fontSize: '18px',
            height: '18px',
            color: activeTab === '/' ? '#fff' : '#96A0B5',
          }}
        />
      ),
      handleClick: () => setActiveTab('/resume-builder'),
    },

  ];

  const getBoard = React.useCallback(
    (callback?: any) => {
      setBoardLoading(true);

      if (!studentData?.userId) {
        setBoardLoading(false);
        setBoard(null);
        return;
      }
      axios
        .get(`${jobTrackerApiPath}/board?userId=${studentData?.userId}`)
        .then((res) => {
          setBoard(res.data);
          setSectionList(res.data.sectionList as any[]);
          setDeletedJobList((res.data?.deletedJobCardList as any[]) || []);
          if (callback) callback(res.data);
        })
        .catch((err) => {
          setBoardLoading(false);
          setBoard(null);
          console.log(err);
        })
        .finally(() => setBoardLoading(false));
    },
    [studentData]
  );
  React.useEffect(() => {
    getBoard();
  }, [getBoard]);

  React.useEffect(() => {
    void (async () => {
      try {
        const response = await downLoadDisplayPicture(
          studentData?.profilePhotoPath as string
        );
        if (response) {
          setProfilePic(response);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [studentData?.profilePhotoPath]);

  if (studentDataLoading) return <Loader />;
  return (
    <div className={styles.root}>
      <Row style={{ width: '100%' }}>
        <Col
          style={{
            flex: '0 0 250px',
            width: '230px',
            backgroundColor: '#E1EDFF',
            height: '100%',
            zIndex: 1,
            position: 'fixed',
            minHeight: '90vh',
            bottom: '0rem',
            top: '4.5rem',
          }}
        >
          <LeftSubNav
            profilePic={profilePic}
            userData={studentData}
            menuItems={menuItems}
            activeTab={activeTab}
            displayTitle={studentName}
            aiMenuItems={null}
          />
        </Col>

        <Col style={{ marginLeft: '230px', width: 'calc(100% - 230px)' }}>
          <Suspense fallback={<Loader />}>
            <Outlet
              context={{
                board,
                sectionList,
                setSectionList,
                deletedJobList,
                boardLoading,
                getBoard,
                student: studentData,
                reload,
                setReload,
                studentInvites,
              }}
            />
          </Suspense>
        </Col>
      </Row>
    </div>
  );
}

export default StudentDetail;
