import axios, { InternalAxiosRequestConfig } from 'axios';
import { auth } from '../utils/firebase/firebaseIndex';

axios.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    if (config && config.headers && auth.currentUser) {
      const accessToken = await auth.currentUser.getIdToken();
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);
