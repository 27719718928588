import { message } from 'antd';
import axios from 'axios';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import ReactGA from 'react-ga4';
import { createSearchParams, useNavigate } from 'react-router-dom';
import InstituteService from '../services/InstituteService';
import { analyticsEvent } from '../utils/analytics';

import {
  instituteApiPath,
  miscApiPath,
  userDetailApiPath,
  userRoleApiPath,
} from '../utils/apiPaths';
import {
  auth,
  logInWithEmailAndPassword,
  logout,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from '../utils/firebase/firebaseIndex';

import { useTranslation } from 'react-i18next';

export interface InitialState {
  signInWithGoogleCall: () => Promise<void>;
  isLoggedIn: boolean;
  userInfo: any;
  authToken: string | null;
  user?: any;
  newUser: boolean;
  setNewUser: any;
  setUserInfo: any;
  newUserSignupComplete: boolean;
  loading: boolean;
  appConfig: any;
  signupWithEmailAndPassword: (params: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => Promise<void>;
  signinWithEmailAndPassword: (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ) => Promise<void>;
  setIsExtensionInstalled: any;
  isExtensionInstalled: any;
  userVerified: boolean;
  studentName: string;
  institute: any;
}

export const AppContext = createContext<InitialState>({
  signInWithGoogleCall: () => Promise.resolve(),
  setIsExtensionInstalled: () => null,
  isLoggedIn: false,
  userInfo: {},
  appConfig: {},
  authToken: null,
  user: {},
  newUser: false,
  setNewUser: () => null,
  setUserInfo: () => null,
  newUserSignupComplete: false,
  loading: false,
  isExtensionInstalled: false,
  signupWithEmailAndPassword: (_params: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => Promise.resolve(),
  signinWithEmailAndPassword: (
    _email: string,
    _password: string,
    _firstName?: string,
    _lastName?: string
  ) => Promise.resolve(),
  userVerified: false,
  studentName: 'student',
  institute: {},
});

interface Props {
  children: any;
}

export let isMobile = false;

function UserProvider({ children }: Props) {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const [user, userLoading] = useAuthState(auth);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [newUserSignupComplete, setNewUserSignupComplete] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [userVerified, setUserVerified] = useState(false);
  const appName = window.location.pathname.split('/')[1];
  const [studentName, setStudentName] = useState('');
  const [appConfig, setAppConfig] = useState<any>({});
  const [userInfo, setUserInfo] = useState({
    fname: '',
    lname: '',
    email: '',
    username: '',
    profilePic: '',
    questionnaire: {},
    phoneNo: '',
    gender: '',
    dob: '',
    userId: '',
    webAppLanguage: '',
  });
  const [institute, setInstitute] = useState<any>();

  const { i18n } = useTranslation();
  const navigate = useNavigate();
  isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    axios
      .get(`${miscApiPath}/webappConfig`)
      .then((res) => {
        if (res.data.content) setAppConfig(res.data.content);
      })
      .catch((error) => console.log(error));

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if ('webAppLanguage' in userInfo) {
      i18n
        .changeLanguage(userInfo?.webAppLanguage)
        .catch((error) => console.log(error));
    }
  }, [i18n, userInfo]);

  useEffect(() => {
    const loggedInApp = localStorage.getItem('loggedInApp');
    if (!user && !userLoading) {
      return localStorage.removeItem('loggedInApp');
    }
    if (loggedInApp && loggedInApp !== appName && user) {
      window.location.href = `/${loggedInApp}`;
    }
  }, [appName, user, userLoading]);

  useEffect(() => {
    InstituteService.getInstitute(appName)
      .then(({ data }: any) => {
        if (data === 'OK') {
          void message.error('Institute url not valid.');
          setTimeout(() => {
            return (window.location.href = window.location.origin);
          }, 1000);
        }
        setInstitute(data);
      })
      .catch((error) => console.log(error));
  }, [appName]);

  const isLoggedIn = localStorage.getItem('isLoggedIn');

  const getUserRole = useCallback(() => {
    axios
      .get(userRoleApiPath)
      .then(async (userRoleGetResponse) => {
        if (
          !(
            userRoleGetResponse.data.instituteRoles?.[appName] === 'COACH' ||
            userRoleGetResponse.data.instituteRoles?.[appName] === 'ADMIN'
          )
        ) {
          void message.error(
            "You don't have access to this app. Please contact institute admin."
          );
          return logout();
        }

        setUserInfo((userInfo) => {
          return {
            ...userRoleGetResponse.data,
            ...userInfo,
            username: `${
              userRoleGetResponse.data?.fname
            } ${userRoleGetResponse.data?.lname}`,
            fname: userRoleGetResponse.data?.fname,
            lname: userRoleGetResponse.data?.lname,
            email: userRoleGetResponse.data?.email,
            userId: userRoleGetResponse.data?.id,
          };
        });

        await axios.get(
          `${instituteApiPath}/institute/${appName}/verifyInstituteCoach/${
            userRoleGetResponse.data?.email
          }`
        );
        setUserVerified(true);
      })
      .catch((error) => console.log(error));
  }, [appName]);

  const getUserDetails = useCallback(() => {
    axios
      .get(userDetailApiPath)
      .then((userDetailGetResponse) => {
        setUserInfo((userInfo: any) => {
          return {
            ...userInfo,
            profilePic: userDetailGetResponse.data?.[0]?.profilePhoto,
            questionnaire: userDetailGetResponse.data?.[0]?.questionnaire,
            phoneNo: userDetailGetResponse?.data[0]?.phoneNo,
            gender: userDetailGetResponse?.data[0]?.gender,
            dob: userDetailGetResponse?.data[0]?.dob,
            webAppLanguage: userDetailGetResponse?.data[0]?.webAppLanguage,
          };
        });
      })
      .catch((error) => console.log(error));
  }, []);

  const fetchAppData = useCallback(() => {
    getUserRole();
    getUserDetails();
    InstituteService.getInstitute(appName)
      .then(({ data }: any) => {
        data?.student && data?.student !== ''
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setStudentName(data?.student)
          : setStudentName('Student');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        localStorage.setItem('studentName', data?.student || 'Student');
      })
      .catch((error) => console.log(error));
  }, [appName, getUserDetails, getUserRole]);

  useEffect(() => {
    if (!!user && isLoggedIn === 'true' && !newUser) {
      try {
        fetchAppData();
      } catch (err) {
        console.log('err :>> ', err);
      }
    }
  }, [user, appName, isLoggedIn, newUser, fetchAppData]);

  useEffect(() => {
    const storedStudentName = localStorage.getItem('studentName');
    if (storedStudentName) {
      setStudentName(storedStudentName);
    }
  }, []);

  useEffect(() => {
    if (!!user && !newUser) {
      getUserDetails();
    }
  }, [getUserDetails, user, newUser]);

  const signup = async (authResponse: any) => {
    const { displayName } = authResponse.user;
    const numberOfWords = displayName.split(' ').length;
    const firstname = displayName
      ? displayName.split(' ').slice(0, -1).join(' ')
      : '';
    const lastname =
      numberOfWords > 1 && displayName ? displayName.split(' ').pop() : '';

    const userRoleRequestBody = {
      role: 'MENTEE',
      fname: firstname, // get values from usestate object
      lname: lastname,
      mentorStatus: '',
      profilePhoto: authResponse.user.photoURL || '',
      linkedin: '',
      mentorRating: 0,
      instituteRoles: { [appName]: 'COACH' },
    };

    await axios.post(userRoleApiPath, userRoleRequestBody);
    fetchAppData();

    localStorage.setItem('isLoggedIn', 'true');
  };

  const sendTokenToChromeExtension = ({ jwt, user }: any) => {
    try {
      chrome.runtime.sendMessage(
        process.env.VITE_APP_EXTENSION_ID,
        { jwt, user },
        (response: any) => {
          if (response && !response.success) {
            console.log('error sending message', response);
            return response;
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const signInWithGoogleCall = async () => {
    analyticsEvent('Sign in with google', 'Login');

    ReactGA.event({
      category: 'Login',
      action: 'Sign in with google',
    });

    try {
      const authResponse: any = await signInWithGoogle();
      try {
        await axios.get(
          `${instituteApiPath}/institute/${appName}/verifyInstituteCoach/${authResponse.user.email}`
        );
        setUserVerified(true);
      } catch (e) {
        void message.error(
          'This email is not registered. Please contact your admin.'
        );
        void logout();
      }

      // extension sign in
      const { isNewUser, idToken } = authResponse._tokenResponse;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setAuthToken(idToken);

      if (isNewUser) {
        setNewUser(true);
        analyticsEvent('New User Sign In', 'SignIn');
        ReactGA.event({
          category: 'SignIn',
          action: 'New User Sign In',
        });
        await signup(authResponse);
        setNewUserSignupComplete(true);
        getUserDetails();
      } else {
        setNewUser(false);
      }
      navigate(`/${studentName.toLowerCase()}`);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('loggedInApp', appName);

      sendTokenToChromeExtension({
        jwt: idToken,
        user: authResponse.user,
      });

      if (newUser) {
        return;
      }
      getUserDetails();
    } catch {
      navigate('/login');
    }
  };

  const signupWithEmailAndPassword = async ({
    email,
    password,
    firstName,
    lastName,
  }: any) => {
    try {
      try {
        await axios.get(
          `${instituteApiPath}/institute/${appName}/verifyInstituteCoach/${email}`
        );
      } catch (e) {
        void message.error(
          'This email is not registered. Please contact your admin.'
        );
        return;
      }
      // check if the coach exists with this email or not, else cancel the process.
      void message.info('Signing up the new user');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      await registerWithEmailAndPassword(email, password);
      localStorage.setItem('loggedInApp', appName);
      localStorage.setItem('isLoggedIn', 'true');
      const userRoleRequestBody = {
        role: 'MENTEE',
        fname: firstName,
        lname: lastName,
        mentorStatus: '',
        profilePhoto: '',
        linkedin: '',
        mentorRating: 0,
        instituteRoles: { [appName]: 'COACH' }, // coach or admin
      };

      await axios.post(userRoleApiPath, userRoleRequestBody);

      navigate('/');
      ReactGA.event({
        category: 'SignIn',
        action: 'New User Sign In',
      });
      getUserDetails();
    } catch (error: any) {
      void message.error(`${error}`.replace('FirebaseError: Firebase:', ''));
      navigate('/login');
    }
  };

  const signinWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    let userRes: any;
    try {
      try {
        userRes = (
          await axios.get(
            `${instituteApiPath}/institute/${appName}/verifyInstituteCoach/${email}`
          )
        ).data;
      } catch (e) {
        void message.error(
          'This email is not registered. Please contact your admin.'
        );
        return;
      }

      setUserVerified(true);

      const authResponse = await logInWithEmailAndPassword(email, password);
      await authResponse.user.getIdToken();
      localStorage.setItem('loggedInApp', appName);
      localStorage.setItem('isLoggedIn', 'true');

      navigate('/');
    } catch (error: any) {
      if (
        error.toString() ===
        'FirebaseError: Firebase: Error (auth/user-not-found).'
      ) {
        navigate({
          pathname: '/login',
          search: createSearchParams({
            register: 'true',
          }).toString(),
        });
      } else {
        void message.error(`${error}`);
      }
    }
  };

  return (
    <AppContext.Provider
      value={{
        signInWithGoogleCall,
        isLoggedIn: !!user,
        userInfo,
        authToken,
        newUser,
        newUserSignupComplete,
        appConfig,
        setNewUser,
        isExtensionInstalled,
        setIsExtensionInstalled,
        setUserInfo,
        loading: userLoading,
        signupWithEmailAndPassword,
        signinWithEmailAndPassword,
        userVerified,
        studentName,
        institute,
        user,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default UserProvider;
