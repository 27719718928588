import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import en from '../locales/en/translation.json';
import bg from '../locales/bg/translation.json';
import cs from '../locales/cs/translation.json';
import da from '../locales/da/translation.json';
import de from '../locales/de/translation.json';
import es from '../locales/es/translation.json';
import et from '../locales/et/translation.json';
import fi from '../locales/fi/translation.json';
import fr from '../locales/fr/translation.json';
import hi from '../locales/hi/translation.json';
import hr from '../locales/hr/translation.json';
import hu from '../locales/hu/translation.json';
import it from '../locales/it/translation.json';
import lt from '../locales/lt/translation.json';
import lv from '../locales/lv/translation.json';
import nl from '../locales/nl/translation.json';
import no from '../locales/no/translation.json';
import pl from '../locales/pl/translation.json';
import pt from '../locales/pt/translation.json';
import ro from '../locales/ro/translation.json';
import sk from '../locales/sk/translation.json';
import sl from '../locales/sl/translation.json';
import sr from '../locales/sr/translation.json';
import sv from '../locales/sv/translation.json';
import zh from '../locales/zh/translation.json';

export const availableLocales: any = {
  en: en,
  bg: bg,
  cs: cs,
  da: da,
  de: de,
  es: es,
  et: et,
  fi: fi,
  fr: fr,
  hi: hi,
  hr: hr,
  hu: hu,
  it: it,
  lt: lt,
  lv: lv,
  nl: nl,
  no: no,
  pl: pl,
  pt: pt,
  ro: ro,
  sk: sk,
  sl: sl,
  sr: sr,
  sv: sv,
  zh: zh,
};

void i18n.use(Backend).use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
});

const resources = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  ...Object.keys(availableLocales).reduce((prev: any, curr: any) => {
    prev[curr] = { translation: availableLocales[curr] };
    return prev;
  }, {}),
} as const;

void i18n.use(Backend).use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  resources,
});

export default i18n;
