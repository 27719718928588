import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../auth';
import { ROUTES } from '../types/Routes';
import { Layout } from 'antd';
import Loader from '../components/Loader';
import Navbar from '../components/Navbar/Navbar';
import SideNav from '../components/SideNav/SideNav';
import MainLoader from '../components/MainLoader';
import { useMediaQuery } from 'react-responsive';

export const CommonLayout = ({ institute }: { institute: any }) => {
  const { Header, Content, Sider } = Layout;

  const [isMenuUnfolded, setIsMenuUnfolded] = useState(false);
  const [collapsed, setCollapsed] = useState(!true);
  const [back, setBack] = useState(false);
  const [sideMenu, setSideMenu] = useState('1');

  const { studentName } = useContext(AppContext);
  const location = useLocation();
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const studentNameLowerCase = studentName.toLowerCase();

  useEffect(() => {
    if (
      location.pathname.startsWith(
        `/${encodeURIComponent(studentNameLowerCase)}/`
      ) ||
      location.pathname.startsWith('/my-teams/')
    ) {
      setCollapsed(true);
      setBack(true);
    } else {
      if (!isDesktop) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
      setBack(false);
    }
  }, [setCollapsed, setBack, studentNameLowerCase, isDesktop, location.pathname]);

  if (!institute) return <Loader />;

  return (
    <Fragment>
      <Header style={{ minHeight: '4.5rem' }}>
        <Navbar
          isMenuUnfolded={isMenuUnfolded}
          setIsMenuUnfolded={setIsMenuUnfolded}
          shrink={collapsed}
          back={back}
        />
      </Header>

      {!isMenuUnfolded && (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            height: '100%',
            position: 'fixed',
            zIndex: 2,
            top: 0,
          }}
          width={231}
          collapsedWidth={70}
          className="site-layout-background"
        >
          <SideNav
            setSideMenu={setSideMenu}
            sideMenu={sideMenu}
            shrink={collapsed}
            setShrink={setCollapsed}
            institute={institute}
          />
        </Sider>
      )}

      <Content
        className="site-layout-background"
        style={{
          minHeight: 'calc(100vh - 5.5rem)',
          backgroundColor: '#FAFAFA',
          position: 'absolute',
          left: collapsed
            ? isMenuUnfolded
              ? 0
              : 70
            : !isMenuUnfolded
              ? '230px'
              : '0',
          width: `calc(100% - ${collapsed ? (isMenuUnfolded ? 0 : 70) : !isMenuUnfolded ? 230 : 0}px)`,
        }}
      >
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Content>
    </Fragment>
  );
};

export const ProtectedRoute = () => {
  const { user, loading } = useContext(AppContext);
  const appName = window.location.pathname.split('/')[1];
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user && appName) {
      navigate(ROUTES.LOGIN);
      return;
    }
  }, [appName, loading, navigate, user]);

  if (loading) return <MainLoader />;

  if (!loading && user) {
    return <Outlet />;
  }
  return <React.Fragment />;
};

export const LayoutOnboarding = () => {
  const { user, loading } = useContext(AppContext);
  const appName = window.location.pathname.split('/')[1];
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && user && appName) {
      navigate(ROUTES.HOME);
      return;
    }
  }, [user, navigate, loading, appName]);

  if (loading) return <MainLoader />;

  if (!loading && !user) {
    return <Outlet />;
  }

  return <React.Fragment />;
};
